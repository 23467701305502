import * as React from "react"
import { Helmet } from "react-helmet"
import WordRoll from "../../components/wordroll"

import NotoSansJPMedium from "../../assets/fonts/NotoSansJP-Medium.woff2"
import NotoSansKRMedium from "../../assets/fonts/NotoSansKR-Medium.woff2"
import NotoSansTCMedium from "../../assets/fonts/NotoSansTC-Medium.woff2"
import NotoSansJPBold from "../../assets/fonts/NotoSansJP-Bold.woff2"
import NotoSansKRBold from "../../assets/fonts/NotoSansKR-Bold.woff2"
import NotoSansTCBold from "../../assets/fonts/NotoSansTC-Bold.woff2"

const fonts = [
  NotoSansJPMedium,
  NotoSansKRMedium,
  NotoSansTCMedium,
  NotoSansJPBold,
  NotoSansKRBold,
  NotoSansTCBold,
]

const CharactersPage = () => {
  return (
    <main>
      <Helmet>
        {fonts.map((font, i) => (
          <link
            key={i}
            rel="preload"
            as="font"
            href={font}
            type="font/woff2"
            crossOrigin="anonymous"
          />
        ))}
        <title>808CJK | Learn Chinese, Japanese, and Korean</title>
        <meta
          name="title"
          content="808CJK | Learn Chinese, Japanese, and Korean"
        />
        <meta
          name="description"
          content="Learn Chinese, Japanese, and Korean through 808 commonly used characters shared between all three languages."
        />
        <meta name="keywords" content="language, learning" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta property="og:title" content="808CJK" />
        <meta property="og:site_name" content="808CJK" />
        <meta property="og:url" content="https://www.808cjk.com" />
        <meta
          property="og:description"
          content="Learn Chinese, Japanese, and Korean through 808 commonly used characters shared between all three languages."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.808cjk.com/img/logo.jpg"
        />
        <link rel="canonical" href="https://www.808cjk.com" />
      </Helmet>
      <WordRoll />
    </main>
  )
}

export default CharactersPage
