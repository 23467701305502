import React, { useState, useEffect } from "react"
import clsx from "clsx"
import Header from "./header"
import Footer from "./footer"
import Context from "../context"
import { languages } from "../lib/languages"

import logo from "../assets/images/808cjk.jpg"
import iconClose from "../assets/images/icon-close.svg"

import "../stylesheets/main.scss"

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function Layout({ children, location }) {
  const { pathname } = location
  const [mainLang, setMainLang] = useState(languages.C)

  const changeMainLang = (newLang) => {
    setMainLang(newLang)
  }

  // TODO: extract to separate component
  const [wiggle, setWiggle] = useState(false)
  const [emailIsValid, setEmailIsValid] = useState()
  const [email, setEmail] = useState("")

  let hiddenNewsletter = 0
  if (typeof window !== "undefined") {
    // FIXME: API-ify if user has already registered to newsletter
    const storedValueSession = sessionStorage.getItem("hideNewsletter")
    const storedValueLocal = localStorage.getItem("joinedNewsletter")
    if (storedValueSession !== null) {
      hiddenNewsletter = parseInt(storedValueSession, 10)
    }

    if (storedValueLocal !== null) {
      hiddenNewsletter = parseInt(storedValueLocal, 10)
    }
  }

  const [isNewsletterCtaVisible, setIsNewsLetterCtaVisible] = useState(
    hiddenNewsletter === 0,
  )

  const handleNewsletterClose = () => {
    sessionStorage.setItem("hideNewsletter", "1")
    setIsNewsLetterCtaVisible(false)
  }

  const checkEmail = (email) => {
    const pattern = new RegExp(/^.+@.+\..+/i)

    let isValid = true

    if (!pattern.test(email)) {
      isValid = false
    }

    return isValid
  }

  const triggerWiggle = () => {
    setWiggle(true)

    setTimeout(() => {
      setWiggle(false)
    }, 500) // NOTE: timeout should match animation duration
  }

  useEffect(() => {
    if (email) {
      setEmailIsValid(true)
    }
  }, [email])

  const handleChange = (e) => {
    setEmail(e.currentTarget.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    if (checkEmail(email)) {
      const form = e.target
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": form.getAttribute("name"),
          email,
        }),
      })
        .then(() => {
          setIsNewsLetterCtaVisible(false)
          if (typeof window !== "undefined") {
            localStorage.setItem("joinedNewsletter", "1")
          }
          alert("Thank you for subscribing!")
        })
        .catch((error) => alert(error))
    } else {
      setEmailIsValid(false)
      triggerWiggle()
    }
  }

  return (
    <Context.Provider
      value={{
        mainLang,
        changeMainLang,
        pathname,
      }}
    >
      <div
        className={clsx("layout-wrapper", mainLang, {
          "overflow-hidden": pathname === "/",
        })}
      >
        <div className="layout">
          <Header />
          <div>{children}</div>
          <div
            className={clsx("newsletter", {
              "is-visible": isNewsletterCtaVisible,
            })}
          >
            <div
              className="newsletter-close"
              role="button"
              onClick={handleNewsletterClose}
              onKeyDown={handleNewsletterClose}
              tabIndex={0}
            >
              <img src={iconClose} alt="close" />
            </div>
            <div className="newsletter-text">
              <div className="newsletter-text-title">
                Join our newsletter, or else
              </div>
              <div className="newsletter-text-subtitle">
                you'll miss out on language learning tips and stuff.
              </div>
            </div>
            <div className="newsletter-cta-wrapper">
              <form
                className={clsx("newsletter-cta", {
                  "is-wiggling": wiggle,
                  "is-invalid": emailIsValid === false,
                })}
                name="newsletter"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input
                  type="hidden"
                  name="newsletter-members"
                  value="newsletter"
                />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={handleChange} />
                  </label>
                </div>
                <input
                  className="input newsletter-input"
                  type={"text"}
                  name={"email"}
                  onChange={handleChange}
                  value={email}
                  id={"email"}
                  required={true}
                  aria-label="Email"
                  placeholder="Enter your email"
                />
                <button className="button newsletter-button" type="submit">
                  Join
                </button>
              </form>
              <div className="mascot">
                <img src={logo} alt="808cjk mascot" />
              </div>
            </div>
          </div>

          <Footer />
        </div>
      </div>
    </Context.Provider>
  )
}
