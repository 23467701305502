import React, { useContext } from "react"
import { Link } from "gatsby"
import clsx from "clsx"
import Context from "../context"
import { languages } from "../lib/languages"

import flagCn from "../assets/images/flag-cn.svg"
import flagJp from "../assets/images/flag-jp.svg"
import flagKr from "../assets/images/flag-kr.svg"

const languageTriggers = [
  {
    flagImg: flagCn,
    flagImgAlt: "the People's Republic of China's flag",
    lang: languages.C,
  },
  {
    flagImg: flagJp,
    flagImgAlt: "Japan's flag",
    lang: languages.J,
  },
  {
    flagImg: flagKr,
    flagImgAlt: "the Republic of Korea's flag",
    lang: languages.K,
  },
]

const Header = () => {
  const { mainLang, changeMainLang, pathname } = useContext(Context)

  return (
    <header className="header">
      <div className="header-navlinks">
        <Link to="/" className="header-link">
          808CJK
        </Link>
        <Link to="/blog" className="header-link">
          Blog
        </Link>
      </div>
      {pathname === "/" && (
        <div className="header-langs">
          {languageTriggers.map((country) => {
            return (
              <div
                key={country.lang}
                className={clsx("lang", country.lang, {
                  "is-active": country.lang === mainLang,
                })}
                onClick={() => {
                  changeMainLang(country.lang)
                }}
                onKeyDown={() => {
                  changeMainLang(country.lang)
                }}
                role="button"
                tabIndex={0}
              >
                <img src={country.flagImg} alt={country.alt} />
              </div>
            )
          })}
        </div>
      )}
    </header>
  )
}

export default Header
