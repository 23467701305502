import React from "react"

import youtube from "../assets/images/cta-youtube.svg"
import patreon from "../assets/images/cta-patreon.svg"

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-fineprint">
        Words and definitions courtesy of Trilateral Cooperation Secretariat,
        Trilateral Common Vocabulary Dictionary 3rd Edition, tcvd-asia.com
      </div>
      <div className="footer-logos">
        <a
          className="footer-logo-link"
          href="https://www.youtube.com/c/808CJK"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={youtube} alt="subscribe to our youtube channel" />
        </a>
        <a
          className="footer-logo-link"
          href="https://www.patreon.com/808cjk"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={patreon} alt="become a patron on patreon" />
        </a>
      </div>
    </footer>
  )
}

export default Footer
