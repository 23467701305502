import * as React from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import Content, { HTMLContent } from "../components/content"

const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  featuredImage,
  featuredimagealt,
  title,
  helmet,
  tags,
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="blog-post">
      {helmet || ""}
      <h1 className="blog-post-title">{title}</h1>
      {featuredImage && (
        <div className="blog-post-featured-image">
          <img
            src={featuredImage}
            alt={featuredimagealt ? featuredImage : ""}
          />
        </div>
      )}
      <p>{description}</p>
      <PostContent content={content} />
      {/* {tags && tags.length ? (
        <div style={{ marginTop: `4rem` }}>
          <h4>Tags</h4>
          <ul className="taglist">
            {tags.map((tag) => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null} */}
    </section>
  )
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data
  const { frontmatter } = post

  return (
    <main>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${frontmatter.title}`}</title>
            <meta name="description" content={`${frontmatter.description}`} />
          </Helmet>
        }
        tags={frontmatter.tags}
        title={frontmatter.title}
        featuredImage={frontmatter.featuredimage}
      />
    </main>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage
        featuredimagealt
        tags
      }
    }
  }
`
