import * as React from "react"
import { Helmet } from "react-helmet"
import { StaticQuery, graphql, Link } from "gatsby"

const BlogIndexTemplate = ({ data }) => {
  const { allMarkdownRemark } = data
  const { edges } = allMarkdownRemark

  return (
    <div className="blog-post-container">
      <Helmet>
        <meta name="title" content="808CJK | Blog" />
        <meta
          name="description"
          content="A collection of posts for tips on language acquisition and other language-related content, especially concerning Chinese, Japanese, and Korean."
        />
        <meta name="keywords" content="language, learning" />
        <meta name="robots" content="index, follow" />
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
        <meta property="og:title" content="808CJK" />
        <meta property="og:site_name" content="808CJK" />
        <meta property="og:url" content="https://www.808cjk.com/blog" />
        <meta
          property="og:description"
          content="A collection of posts for tips on language acquisition and other language-related content, especially concerning Chinese, Japanese, and Korean."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://www.808cjk.com/img/logo.jpg"
        />
        <link rel="canonical" href="https://www.808cjk.com/blog" />
      </Helmet>
      <h1>Blog Posts</h1>
      <p>Here are the blogs</p>
      {edges.map((edge) => {
        const { node } = edge
        const { frontmatter } = node
        let finalSlug = frontmatter.assignedSlug

        if (finalSlug.charAt(0) === "/") {
          finalSlug = finalSlug.substring(1)
        }

        finalSlug = finalSlug.replace(/blog\//g, "")

        return (
          <div key={node.id}>
            <h2>
              <Link to={`/blog/${finalSlug}`}>{frontmatter.title}</Link>
            </h2>
          </div>
        )
      })}
    </div>
  )
}

export default function BlogIndexPage() {
  return (
    <StaticQuery
      query={graphql`
        query BlogQuery {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
          ) {
            edges {
              node {
                excerpt(pruneLength: 400)
                id
                frontmatter {
                  title
                  templateKey
                  date(formatString: "MMMM DD, YYYY")
                  featuredpost
                  featuredimage
                  assignedSlug
                }
              }
            }
          }
        }
      `}
      render={(data, count) => <BlogIndexTemplate data={data} count={count} />}
    />
  )
}
